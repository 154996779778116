import { DownOutlined, FileTextOutlined } from "@ant-design/icons";
import axios from "axios";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import React, { useRef, useState } from "react";
import useCollapse from "react-collapsed";
import { useTranslation } from "react-i18next";
import { GetAccessCodeResult } from "../../utils/types";
import "./RegformStyle.css";
import { isDateOfBirthValid, isEmailValid } from "../../utils/validation";
import _ from "lodash";

const CollapsibleGuests = ({
  i,
  j,
  bookingItems,
  setBookingItems,
  isRequiredGuestProperties
}: {
  i: any;
  j: any;
  bookingItems: any;
  setBookingItems: any;
  isRequiredGuestProperties: IsRequiredGuestProperty;
}) => {
  const addressEl = useRef<HTMLInputElement>(null);
  const postalCodeEl = useRef<HTMLInputElement>(null);
  const cityEl = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();
  countries.registerLocale(enLocale);

  const countryArr = Object.entries(countries.getNames("en", { select: "official" })).map(([key, value]) => ({
    label: value,
    value: key
  }));
  countryArr.unshift({ label: "Choose a country", value: "" });

  const guest = bookingItems[i].guests[j];
  const isFamilyMember = bookingItems[i].guests[j].isFamilyMember;

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  const changeFormFieldValue = (e: any, source: string) => {
    const _value = e.target.value;
    const _bookingItemsCopy = [...bookingItems];
    _bookingItemsCopy[i].guests[j][source] = _value;
    setBookingItems(_bookingItemsCopy);
    if ("address" === source || "postalCode" === source || "city" === source) setIsFamilyFieldsValueForAllGuests();
  };

  const changeIsFamilyFieldValue = (e: any, source: string) => {
    const _isFamilyMember = e.target.checked;
    const _bookingItemsCopy = [...bookingItems];
    _bookingItemsCopy[i].guests[j][source] = _isFamilyMember;
    addressEl?.current && setInputValidationClass(addressEl.current, true);
    postalCodeEl?.current && setInputValidationClass(postalCodeEl.current, true);
    cityEl?.current && setInputValidationClass(cityEl.current, true);
    if (_isFamilyMember) setIsFamilyFieldsValue(j);
    setBookingItems(_bookingItemsCopy);
  };

  const setIsFamilyFieldsValue = (currentGuestIndex: string) => {
    const _bookingItemsCopy = [...bookingItems];
    _bookingItemsCopy[i].guests[currentGuestIndex]["address"] = _bookingItemsCopy[i].guests[0]["address"];
    _bookingItemsCopy[i].guests[currentGuestIndex]["postalCode"] = _bookingItemsCopy[i].guests[0]["postalCode"];
    _bookingItemsCopy[i].guests[currentGuestIndex]["city"] = _bookingItemsCopy[i].guests[0]["city"];
    setBookingItems(_bookingItemsCopy);
  };

  const setIsFamilyFieldsValueForAllGuests = () => {
    _.forEach(bookingItems[i].guests, (guest, index) => {
      if (guest.isFamilyMember) setIsFamilyFieldsValue(index);
    });
  };

  const validateForm = (e: any, source: string) => {
    const _value = e.target.value;
    setRequiredInputValidationClass(e);
    if (_value && "email" === source) setValidationClassForEmail(e);
    if (_value && "dateofbirth" === source) setValidationClassForDateOfBirth(e);
  };

  const setInputValidationClass = (e: Element, isValid: boolean) => {
    if (isValid) e.classList.remove("propertyForm-invalid");
    else e.classList.add("propertyForm-invalid");
  };

  const setRequiredInputValidationClass = (e: any) => {
    const _input = e.target;
    const _isValid = !(_input.required && _input.value.trim() === "");
    setInputValidationClass(_input, _isValid);
  };

  const setValidationClassForEmail = (e: any) => {
    const _input = e.target;
    const _isValid = isEmailValid(_input.value);
    setInputValidationClass(_input, _isValid);
  };

  const setValidationClassForDateOfBirth = (e: any) => {
    const _input = e.target;
    const _isValid = isDateOfBirthValid(_input.value);
    setInputValidationClass(_input, _isValid);
  };

  return (
    <div className="collapsible">
      <div className="form-collapsed-background" {...getToggleProps()}>
        {isExpanded ? "" : <FileTextOutlined style={{ fontSize: 30 }} />}
        {isExpanded ? <DownOutlined className="arrow" /> : ""}
      </div>
      <div className="collapse-open" {...getCollapseProps()}>
        <div className="content">
          <form id="guest-reg-form" className="regformWrapper">
            <span className="inline">
              <label>
                <p>
                  {t("registration.fname")}
                  {isRequiredGuestProperties.firstName && "*"}
                </p>
                <input
                  className="inputField"
                  type="text"
                  required={isRequiredGuestProperties.firstName}
                  placeholder={t("registration.fname")}
                  value={guest.firstName}
                  onChange={e => changeFormFieldValue(e, "firstName")}
                  onBlur={e => validateForm(e, "firstName")}
                />
              </label>
              <label>
                <p>
                  {t("registration.lname")}
                  {isRequiredGuestProperties.lastName && "*"}
                </p>
                <input
                  className="inputField"
                  type="text"
                  required={isRequiredGuestProperties.lastName}
                  placeholder={t("registration.lname")}
                  value={guest.lastName}
                  onChange={e => changeFormFieldValue(e, "lastName")}
                  onBlur={e => validateForm(e, "lastName")}
                />
              </label>
            </span>
            <span className="inline">
              <label>
                <p>
                  {t("registration.dateofbirth")}
                  {isRequiredGuestProperties.dateOfBirth && "*"}
                </p>
                <input
                  className="inputField"
                  type="date"
                  required={isRequiredGuestProperties.dateOfBirth}
                  placeholder={t("registration.dateofbirth")}
                  value={guest.dateOfBirth}
                  onChange={e => changeFormFieldValue(e, "dateOfBirth")}
                  onBlur={e => validateForm(e, "dateOfBirth")}
                />
              </label>
              <label>
                <p>
                  {t("registration.placeofbirth")}
                  {isRequiredGuestProperties.placeOfBirth && "*"}
                </p>
                <select
                  className="dropDown"
                  value={guest.placeOfBirth}
                  required={isRequiredGuestProperties.placeOfBirth}
                  onChange={e => changeFormFieldValue(e, "placeOfBirth")}
                  onBlur={e => validateForm(e, "placeOfBirth")}
                >
                  {!!countryArr?.length &&
                    countryArr.map(({ label, value }: any) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                </select>
              </label>
            </span>
            <span className="inline">
              <label>
                <p>
                  {t("registration.gender")}
                  {isRequiredGuestProperties.gender && "*"}
                </p>
                <select
                  className="dropDown"
                  name="gender"
                  value={guest.gender}
                  required={isRequiredGuestProperties.gender}
                  onChange={e => changeFormFieldValue(e, "gender")}
                  onBlur={e => validateForm(e, "gender")}
                >
                  <option value="">{t("registration.choosegender")}</option>
                  <option value="Male">{t("registration.male")}</option>
                  <option value="Female">{t("registration.female")}</option>
                  <option value="Other">{t("registration.other")}</option>
                </select>
              </label>

              <label>
                <p>
                  {t("registration.nationality")}
                  {isRequiredGuestProperties.nationality && "*"}
                </p>
                <select
                  className="dropDown"
                  value={guest.nationality}
                  required={isRequiredGuestProperties.nationality}
                  onChange={e => changeFormFieldValue(e, "nationality")}
                  onBlur={e => validateForm(e, "nationality")}
                >
                  {!!countryArr?.length &&
                    countryArr.map(({ label, value }: any) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                </select>
              </label>
            </span>
            {j !== 0 ? (
              <label>
                <p>{t("registration.familyMember")}</p>
                <div className="inputField-familyMember-wrapper">
                  <input
                    type="checkbox"
                    id="familyMember"
                    name="familyMember"
                    onChange={e => changeIsFamilyFieldValue(e, "isFamilyMember")}
                    checked={guest.isFamilyMember}
                  ></input>
                </div>
              </label>
            ) : (
              ""
            )}
            <span className="inline">
              <label>
                <p>
                  {t("registration.street")}
                  {isRequiredGuestProperties.address && "*"}
                </p>
                <input
                  className="inputField"
                  type="text"
                  disabled={isFamilyMember}
                  placeholder={t("registration.street")}
                  value={guest.address}
                  required={isRequiredGuestProperties.address}
                  ref={addressEl}
                  onChange={e => changeFormFieldValue(e, "address")}
                  onBlur={e => validateForm(e, "address")}
                />
              </label>
              <label>
                <p>
                  {t("registration.postalcode")}
                  {isRequiredGuestProperties.postalCode && "*"}
                </p>
                <input
                  className="inputField"
                  type="text"
                  disabled={isFamilyMember}
                  placeholder={t("registration.postalcode")}
                  value={guest.postalCode}
                  required={isRequiredGuestProperties.postalCode}
                  ref={postalCodeEl}
                  onChange={e => changeFormFieldValue(e, "postalCode")}
                  onBlur={e => validateForm(e, "postalCode")}
                />
              </label>
            </span>
            <span className="inline">
              <label>
                <p>
                  {t("registration.city")}
                  {isRequiredGuestProperties.city && "*"}
                </p>
                <input
                  className="inputField"
                  type="text"
                  disabled={isFamilyMember}
                  placeholder={t("registration.city")}
                  value={guest.city}
                  required={isRequiredGuestProperties.city}
                  ref={cityEl}
                  onChange={e => changeFormFieldValue(e, "city")}
                  onBlur={e => validateForm(e, "city")}
                />
              </label>
              <label>
                <p>
                  {t("registration.country")}
                  {isRequiredGuestProperties.country && "*"}
                </p>
                <select
                  className="dropDown"
                  value={guest.country}
                  required={isRequiredGuestProperties.country}
                  onChange={e => changeFormFieldValue(e, "country")}
                  onBlur={e => validateForm(e, "country")}
                >
                  {!!countryArr?.length &&
                    countryArr.map(({ label, value }: any) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                </select>
              </label>
            </span>
            <span className="inline">
              <label>
                <p>
                  {t("registration.id")}
                  {isRequiredGuestProperties.typeOfId && "*"}
                </p>
                <select
                  className="dropDown"
                  name="typeOfID"
                  value={guest.typeOfID}
                  required={isRequiredGuestProperties.typeOfId}
                  onChange={e => changeFormFieldValue(e, "typeOfID")}
                  onBlur={e => validateForm(e, "typeOfID")}
                >
                  <option value="">{t("registration.Def")}</option>
                  <option value="idNumber">{t("registration.idNumberOpt")}</option>
                  <option value="passport">{t("registration.passportOpt")}</option>
                  <option value="drivingLicense">{t("registration.drivingLicenseOpt")}</option>
                </select>
              </label>

              <label>
                <p>
                  {t("registration.passport")}
                  {isRequiredGuestProperties.idNumber && "*"}
                </p>
                <input
                  className="inputField"
                  type="text"
                  placeholder={t("registration.passport")}
                  value={guest.idNumber}
                  required={isRequiredGuestProperties.idNumber}
                  onChange={e => changeFormFieldValue(e, "idNumber")}
                  onBlur={e => validateForm(e, "idNumber")}
                />
              </label>
            </span>
            <label>
              <p>
                {t("registration.email")}
                {isRequiredGuestProperties.email && "*"}
              </p>
              <input
                className="inputField-email"
                type="email"
                placeholder={t("registration.email")}
                value={guest.email}
                required={isRequiredGuestProperties.email}
                onChange={e => changeFormFieldValue(e, "email")}
                onBlur={e => validateForm(e, "email")}
              />
            </label>
          </form>
        </div>
      </div>
    </div>
  );
};

const CollapsibleAccessCode = ({ propertyname, bookingId, backendUrl }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [accessData, setAccessData] = useState<GetAccessCodeResult>();
  const [hasValidDate, setHasValidDate] = useState<boolean>(false);
  const { getToggleProps, isExpanded, setExpanded } = useCollapse();

  const handleClick = async () => {
    if (isExpanded) {
      setExpanded(false);
      return;
    }
    const _accessCodeResult: GetAccessCodeResult | null = await callGetAccessCode();
    if (!_accessCodeResult || _accessCodeResult?.severity === "warning" || _accessCodeResult?.severity === "error")
      return;
    setExpanded(prev => !prev);
  };

  const callGetAccessCode = async (): Promise<GetAccessCodeResult | null> => {
    try {
      setTimeout(() => {
        setIsLoading(true);
      }, 1);

      const config = {
        method: "post",
        url: `${backendUrl}getAccessCode`,
        headers: { "Content-Type": "application/json" },
        data: {
          bookingId
        }
      };
      const response = await axios(config);
      const getAccessCodeResult: GetAccessCodeResult = response.data;
      setAccessData(getAccessCodeResult);
      setHasValidDate(!!getAccessCodeResult.validFrom?._seconds && !!getAccessCodeResult.validUntil?._seconds);
      if (getAccessCodeResult.severity === "error") {
        alert(`Error: ${getAccessCodeResult.error?.toString()}`);
      } else if (getAccessCodeResult.severity === "warning") {
        alert(`Warning: ${getAccessCodeResult.error}`);
      }
      return getAccessCodeResult;
    } catch (error: any) {
      console.log(error);
      alert(`There was an error on our end, please contact support. Messsage: ${error.message}`);
    } finally {
      setIsLoading(false); // Disable loading when done
    }
    return null;
  };

  useTranslation();
  countries.registerLocale(enLocale);

  const countryArr = Object.entries(countries.getNames("en", { select: "official" })).map(([key, value]) => ({
    label: value,
    value: key
  }));
  countryArr.unshift({ label: "Choose a country", value: "CHOOSE_COUNTRY" });

  const gateDate = (second: number): string => {
    const _date = new Date(second * 1000);
    let formattedDate = _date.toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      hour12: true
    });
    formattedDate = formattedDate.replace("am", "AM").replace("pm", "PM");
    return formattedDate;
  };

  const isAccessKeyActive = (): boolean => {
    if (!accessData) return false;
    if (!hasValidDate) return true;
    let _isActive = true;
    const _timeStampNow = Date.now();
    if (accessData.validFrom?._seconds && accessData.validFrom._seconds * 1000 > _timeStampNow) _isActive = false;
    if (accessData.validUntil?._seconds && accessData.validUntil._seconds * 1000 < _timeStampNow) _isActive = false;
    return _isActive;
  };

  return (
    <div className="control-apartment-collapsible">
      <div className="control-apartment-collapsed-background" {...getToggleProps({ onClick: handleClick })}>
        {isExpanded ? "Hide Entry Code" : "Entry Code"}
      </div>
      {isLoading && (
        <div className="loading-overlay">
          <p className="loading-text">Loading...</p>
        </div>
      )}
      <div className={`entry-code-collapse ${isExpanded ? "isExpanded" : ""}`}>
        {accessData && accessData.severity === "normal" && (
          <div className="content">
            <p className="dashboard-guest-loxone-data">The access code for entering apartment</p>
            {accessData?.gateCode && (
              <div className="dashboard-guest-access-content">
                <p className="dashboard-guest-access-title">Gate code:</p>
                <p className="dashboard-guest-access-value">{accessData?.gateCode}</p>
              </div>
            )}
            <div className="dashboard-guest-access-content">
              <p className="dashboard-guest-access-title">{propertyname} Entry Code:</p>
              <p className={`dashboard-guest-access-value ${!isAccessKeyActive() && "inactive"}`}>
                {accessData?.accessCode}
              </p>
              <p className={`dashboard-guest-access-valid-badge ${isAccessKeyActive() ? "active" : "inactive"}`}>
                {isAccessKeyActive() ? "Active" : "Inactive"}
              </p>
            </div>

            {hasValidDate && (
              <>
                <div className="dashboard-guest-access-title center medium" style={{ marginTop: "16px" }}>
                  Active:&nbsp;
                  {accessData?.validFrom ? gateDate(accessData?.validFrom._seconds) : ""}
                  &nbsp;-&nbsp;
                  {accessData?.validUntil ? gateDate(accessData?.validUntil._seconds) : ""}
                </div>
                <div className="dashboard-guest-access-title center medium" style={{ marginTop: "12px" }}>
                  EARLY CHECK-IN / LATE CHECK-OUT
                </div>
                <div className="dashboard-guest-access-title center medium" style={{ marginTop: "8px" }}>
                  Upon request. Please contact us.
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const CollapsibleLoxoneCredentials = ({ url, username, password }: any) => {
  useTranslation();
  countries.registerLocale(enLocale);

  const countryArr = Object.entries(countries.getNames("en", { select: "official" })).map(([key, value]) => ({
    label: value,
    value: key
  }));
  countryArr.unshift({ label: "Choose a country", value: "CHOOSE_COUNTRY" });

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  return (
    <div className="control-apartment-collapsible">
      <div className="control-apartment-collapsed-background" {...getToggleProps()}>
        {isExpanded ? "Hide Information" : ""}
        {isExpanded ? "" : "Control Apartment"}
      </div>
      <div className="collapse-open" {...getCollapseProps()}>
        <div className="content">
          <p className="dashboard-guest-loxone-data">Control your apartment heating, lighting and ...</p>
          <p className="dashboard-guest-loxone-data">
            Dashboard:{" "}
            <a href={url} target="_blank" rel="noreferrer">
              Application URL
            </a>
          </p>
          <div className="loxone-credentials-grid">
            <p className="dashboard-guest-loxone-data">Username: {username}</p>
            <button
              className="loxone-credentials-copy-button"
              onClick={() => {
                navigator.clipboard.writeText(username);
              }}
            >
              Copy
            </button>
            <p className="dashboard-guest-loxone-data">Password: {password}</p>
            <button
              className="loxone-credentials-copy-button"
              onClick={() => {
                navigator.clipboard.writeText(password);
              }}
            >
              Copy
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CollapsibleGuests, CollapsibleAccessCode, CollapsibleLoxoneCredentials };

export interface IsRequiredGuestProperty {
  firstName: boolean;
  lastName: boolean;
  dateOfBirth: boolean;
  placeOfBirth: boolean;
  gender: boolean;
  nationality: boolean;
  address: boolean;
  postalCode: boolean;
  city: boolean;
  country: boolean;
  idNumber: boolean;
  email: boolean;
  typeOfId: boolean;
}
