import axios from "axios";
import LZString from "lz-string";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { findAccessByBookingId } from "../../service/findAccess";
import { getAllProperties, getDomainConfig } from "../../utils/configuration";
import {
  AccessConfigInfo,
  BookingItemTransmitted,
  GetLoginResult,
  LoxoneCredentials,
  PropertyConfig
} from "../../utils/types";
import "./DashboardStyle.css";
import ItemCard from "./ItemCard";

const getGuestToken = async () => {
  await handleLogin();
};

const Dashboard = () => {
  const { t } = useTranslation();
  const [bookingItems, setBookingItems] = useState<Array<BookingItemTransmitted>>([]);
  const [backendBaseUrl, setBackendBaseUrl] = useState<string>("");
  const [directionLinks, setDirectionLinks] = useState<{ [propertyId: string]: string }>({});
  const [propertiesControl, setPropertiesControl] = useState<Map<string, boolean>>(new Map());
  const [loxoneCredentials, setLoxoneCredentials] = useState<Map<string, LoxoneCredentials>>(new Map());

  useEffect(() => {
    const fetchTokenAndData = async () => {
      await getGuestToken();
      setPropertiesControlState();
      setLoxoneCredentialsState();
    };

    fetchTokenAndData();
  }, []);

  const setLoxoneCredentialsState = async () => {
    if (bookingItems === undefined) {
      return;
    }
    const loxoneCredentialsMap: Map<string, LoxoneCredentials> = new Map<string, LoxoneCredentials>();
    for (const bookingItem of bookingItems) {
      const access = await findAccessByBookingId(backendBaseUrl!, bookingItem.itemBookingId);
      if (access == null) {
        console.warn(`No access found for booking ${bookingItem.itemBookingId}`);
      } else if(access?.loxoneGatewayUrl){
        const credentials: LoxoneCredentials = {
          url: access.loxoneGatewayUrl,
          username: access.userName!,
          password: access.userPassword!
        };

        loxoneCredentialsMap.set(bookingItem.itemBookingId, credentials);
      }
    }

    setLoxoneCredentials(loxoneCredentialsMap);
  };

  const setPropertiesControlState = async () => {
    const propertyConfigs: PropertyConfig[] = await getAllProperties();
    const propertiesControlMap: Map<string, boolean> = new Map<string, boolean>();
    const propertiesUrlsMap: Map<string, string> = new Map<string, string>();
    for (const propertyConfig of propertyConfigs) {
      if (propertyConfig.access?.accessConfigInfo != null) {
        if ("propertyEnabledControl" in propertyConfig.access.accessConfigInfo!) {
          propertiesControlMap.set(
            propertyConfig.originId!,
            propertyConfig.access.accessConfigInfo.propertyEnabledControl!
          );
        }
        if ("loxoneGatewayUrl" in propertyConfig.access.accessConfigInfo!) {
          const loxoneGatewayUrl = (propertyConfig.access.accessConfigInfo! as AccessConfigInfo).loxoneGatewayUrl;
          propertiesUrlsMap.set(propertyConfig.originId!, loxoneGatewayUrl);
        }
      }
    }

    setPropertiesControl(propertiesControlMap);
  };

  const params: any = {};
  window.location.search
    .slice(1)
    .split("&")
    .forEach((pair: any) => {
      pair = pair.split("=");
      params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    });

  if (bookingItems.length === 0) {
    const decommpressedData = LZString.decompressFromEncodedURIComponent(params.data);
    const dashboardData = JSON.parse(decommpressedData);
    setBackendBaseUrl(dashboardData.backend);
    setBookingItems(dashboardData.items);
    setDirectionLinks(dashboardData.directionLinks);
    if (!dashboardData.backend) console.error("No backend url was specified in the url");
  }

  return (
    <div className="dashboard-wrapper">
      <h1 className="h1-success">{t("successpage.h1-success")}</h1>
      <div className="success-body">
        {bookingItems
          ? bookingItems.map((bookingItem, key) => (
              <ItemCard
                key={key}
                bookingItem={bookingItem}
                backendBaseUrl={backendBaseUrl}
                directionLink={directionLinks[bookingItem.propertyId]}
                propertiesControl={propertiesControl}
                loxoneCredentials={loxoneCredentials}
              />
            ))
          : ""}
      </div>
      <p className="main-info">{t("successpage.mainInfo2")}</p>
      <div className="dashboard-btn-wrap">
        {/*
            <a className="loxone-button" href="https://jobelhome.com">
                Loxone App
                <DownloadOutlined style={{ fontSize: 20 }} />
            </a>
            <a className="invoice-button" href="https://jobelhome.com">
                Invoice download
                <FilePdfOutlined style={{ fontSize: 20 }} />
            </a>*/}
      </div>
    </div>
  );
};

const handleLogin = async () => {
  const callLogin = async () => {
    const domainConfig = await getDomainConfig();

    try {
      const config = {
        method: "post",
        url: `${domainConfig.backendBaseUrl}login`,
        headers: { "Content-Type": "application/json" },
        data: {
          username: "guest",
          password: "guest"
        }
      };
      const response = await axios(config);
      if (response.status === 200) {
        const getLoginResult: GetLoginResult = response.data;
        localStorage.setItem("token", getLoginResult.token);
      }
    } catch (error: any) {
      console.log(error);
      alert(`There was an error on our end, please contact support. Messsage: ${error.message}`);
    }
  };

  await callLogin();
};

export default Dashboard;
